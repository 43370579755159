<template>
    <div>
        <PageTemplateContent
            v-if="entry"
            :entry="entry"
        />
    </div>
</template>

<script setup>
import EntryQuery from '~/graphql/queries/Entry.graphql';

const { variables: defaultVariables } = useCraftGraphql();

const pageVariables = computed(() => {
    return {
        ...defaultVariables,
        section: 'projects',
    };
});

const response = await useAsyncQuery({
    query: EntryQuery,
    variables: pageVariables
});

const { craftEntry: entry } = useCraftPage(response);
useCallToActions(response);
</script>
